<script setup>

import {computed} from "vue";
import {SparklesIcon} from "@heroicons/vue/24/outline";

const props = defineProps({
    user: Object,
    size: {
        type: String,
        default: 'h-6 w-6 '
    }
});

const initials = computed(() => {
    return (props.user.name || '')
        .split(' ')
        .map(name => name.charAt(0).toUpperCase())
        .slice(0, 2)
        .join('')
        ;
});

function hashString(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
}

function mapStringToStyle(str) {
    const hash = hashString(str);
    return styles[hash % styles.length];
}

const styles = [
    'text-white bg-black border-black',
    'text-black bg-white border-black',
    'text-gray-800 bg-gray-100 border-gray-500',
    'text-red-800 bg-red-100 border-red-500',
    'text-yellow-800 bg-yellow-100 border-yellow-500',
    'text-green-800 bg-green-100 border-green-500',
    'text-blue-800 bg-blue-100 border-blue-500',
    'text-indigo-800 bg-indigo-100 border-indigo-500',
    'text-purple-800 bg-purple-100 border-purple-500',
    'text-pink-800 bg-pink-100 border-pink-500',
    'text-rose-800 bg-rose-100 border-rose-500',
    'text-amber-800 bg-amber-100 border-amber-500',
    'text-orange-800 bg-orange-100 border-orange-500',
    'text-lime-800 bg-lime-100 border-lime-500',
    'text-emerald-800 bg-emerald-100 border-emerald-500',
    'text-teal-800 bg-teal-100 border-teal-500',
    'text-cyan-800 bg-cyan-100 border-cyan-500',
    'text-sky-800 bg-sky-100 border-sky-500',
    'text-violet-800 bg-violet-100 border-violet-500',
    'text-fuchsia-800 bg-fuchsia-100 border-fuchsia-500',
];

const style = mapStringToStyle(props.user.name || props.user.participant_type);



</script>

<template>
    <SparklesIcon
        v-if="user.participant_type === 'App\\Models\\TrainingModelVersion'"
        class="size-8"
    />
    <div
        v-else
        :title="user.name"
        :class="size + ' ' + style"
        class="aspect-square rounded-full tracking-tighter text-center flex items-center justify-center overflow-hidden shrink-0 flex-none border"
        v-bind="$attrs">
        <div>{{ initials }}</div>
    </div>
</template>

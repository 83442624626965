import {reactive} from "vue";
import axios from "axios";
import {route} from "ziggy-js";

const store = reactive({
    async get(sortField) {
        try {
            const response = await axios.get(route('portal.api.available-token-bundles', {
                sortField: sortField
            }));

            return response.data;
        } catch (error) {
            console.error(error);
        }

        return false;
    }
});

export default store;

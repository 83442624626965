<script setup>
import {__} from "@/Utils/index.js";
import {Input, Select, Form} from "@/Components/Form";
import {router, usePage} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/20/solid";
import {ButtonTransparent, Button} from "@/Components/Common";
import {ref} from "vue";
import {useForm} from "laravel-precognition-vue-inertia";
import Popper from "vue3-popper";
import {route} from "ziggy-js";

const props = defineProps({
    label: {
        type: String,
        default: __('Tags')
    },
    form: Object,
    id: String,
    required: Boolean,
    placeholder: {
        type: String,
        default: __('Select tags')
    },
    tags: Array,
    actionType: String
});

const select = ref(null);
const input = ref(null);

const page = usePage();
const loading = ref(false);

const loadTags = async (query) => {

    try {
        const response = await axios.get(route('portal.api.tags', {
            account_id: page.props.auth.account_id,
            query: query
        }));

        return response.data;

    } catch (error) {
        console.error('Error loading tags:', error);
    }
}

const createTagForm = useForm('post', route('portal.api.tags.store'), {
    name: ''
})

const createTag = async (close) => {

    try {
        loading.value = true;

        const response = await axios.post(route('portal.api.tags.store'), {
            name: createTagForm.name
        });

        select.value.renderOptions();

        props.form[props.id].push(response.data);

        close();

        loading.value = false;

        router.reload({only: ['success']});
    } catch (error) {
        console.error('Error creating tag: ', error);
        loading.value = false;
    }
}

function resetForm() {
    createTagForm.reset();
    createTagForm.clearErrors();
}

</script>

<template>
    <Select
        ref="select"
        :id
        option-key="id"
        :label
        :placeholder
        :form
        :options="tags ?? loadTags"
        :multiple="true"
        :required
        duskComponentId="tag-selector-component"
        dusk="tag-selector-input"
        @input="event => createTagForm.name = event.target.value">
        <template #suffix v-if="actionType !== 'remove'">
            <Popper @close:popper="resetForm" @open:popper="input.focus()" dusk="tag-selector-add-tag-button">
                <div class="flex items-center space-x-2 text-sm text-purple-600 dark:text-purple-500 cursor-pointer hover:text-black dark:hover:text-white duration-100">
                    <PlusCircleIcon class="size-6"/>
                    <span>{{ __('Add new tag') }}</span>
                </div>
                <template #content="{close}">
                    <div class="border dark:border-slate-700 rounded-lg shadow-lg z-10 mt-3 w-screen mx-4 sm:mx-2 max-w-sm lg:max-w-base" dusk="tag-selector-add-tag-popper">
                        <form>
                            <div class="grid bg-white p-4 grid-rows-2 dark:bg-slate-800 rounded-lg"
                                 dusk="tag-selector-inline-add-tag">
                                <Input
                                    duskComponentId="tag-selector-component-new-tag-name-component"
                                    dusk="tag-selector-component-new-tag-name-input"
                                    ref="input"
                                    id="name"
                                    autofocus
                                    :label="__('Name')"
                                    :form="createTagForm"
                                    required/>
                                <div
                                    class="flex items-center justify-between gap-x-6">
                                    <ButtonTransparent
                                        @click="close">
                                        {{ __('Cancel') }}
                                    </ButtonTransparent>

                                    <Button
                                        dusk="tag-selector-add-tag-submit-button"
                                        :loading="loading"
                                        @click.capture="createTag(close)">
                                        {{ __('Create') }}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </template>
            </Popper>
        </template>
    </Select>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { Cog6ToothIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    loading: Boolean,
    disabled: Boolean,
    icon: {
        type: [Object, Function],
        default: null,
    },
    buttonStyle: {
        type: String,
        default: 'primary',
    },
    type: {
        type: String,
        default: 'submit',
    },
    dataDriverId: {
        type: String,
        default: null,
    },
});

const buttonStyleClasses = computed(() => {
    switch (props.buttonStyle) {
        case 'secondary':
            return 'bg-blue-600 enabled:hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600';
        case 'neutral':
            return 'bg-gray-600 enabled:hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600';
        case 'danger':
            return 'bg-red-600 enabled:hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600';
        case 'primary':
        default:
            return 'bg-purple-600 enabled:hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600';
    }
});

const disabled = ref(props.disabled);

watch(() => props.disabled, (value) => {
    disabled.value = value;
});
</script>

<template>
    <button
        :class="[loading ? 'opacity-100 animate-pulse' : 'disabled:opacity-50', buttonStyleClasses]"
        :disabled="loading || disabled"
        :type="type"
        :data-driver-id="dataDriverId"
        class="flex items-center space-x-2 px-3.5 py-2.5 rounded-full text-white disabled:cursor-not-allowed"
    >
        <component
            v-if="icon && !loading"
            :is="icon"
            class="stroke-2 size-5 mx-3 md:mx-1"
            :class="{'animate-spin': loading}"
        />
        <Cog6ToothIcon
            v-if="loading"
            class="stroke-2 size-5 mx-3 md:mx-1 animate-spin"
        />
        <span v-if="!loading">
            <slot />
        </span>
        <span v-else>
            {{ __('Loading...') }}
        </span>
    </button>
</template>

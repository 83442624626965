import {computed} from "vue";
import {usePage} from "@inertiajs/vue3";
import {formatDate} from "@/Utils";

export default function useCurrentSubscription() {
    const page = usePage();

    // Paid plan details
    const currentSubscription = computed(() => page.props.auth?.account?.subscription);

    //Current subscription dates
    const currentSubscriptionStartPeriod = computed(() => currentSubscription.value?.start_period ? formatDate(currentSubscription.value?.start_period) : null);
    const currentSubscriptionEndPeriod = computed(() => currentSubscription.value?.end_period ? formatDate(currentSubscription.value?.end_period) : null);
    const currentSubscriptionEndsAt = computed(() => currentSubscription.value?.ends_at ? formatDate(currentSubscription.value?.ends_at) : null);

    const currentSubscriptionChangeRequest = computed(() => currentSubscription.value?.active_change_request);
    const currentSubscriptionPrice = computed(() => currentSubscription.value?.price);
    const currentSubscriptionProduct = computed(() => currentSubscriptionPrice.value?.local_product);
    const currentSubscriptionPriceInterval = computed(() => currentSubscriptionPrice.value?.payment_interval);
    const currentSubscriptionIsCanceled = computed(() => !!currentSubscription.value?.ends_at);
    const currentSubscriptionOnGracePeriod = computed(() => currentSubscription.value?.on_grace_period);

    // Trial/Free plan details
    const trialEndsAt = computed(() => page.props.auth?.account?.trial_ends_at ? formatDate(page.props.auth?.account?.trial_ends_at) : null);
    const trialProduct = computed(() => page.props.auth?.account?.trial_product);
    const trialPrice = computed(() => page.props.auth?.account?.trial_price);

    return {
        // Paid plan details
        currentSubscription,

        //Current subscription dates
        currentSubscriptionStartPeriod,
        currentSubscriptionEndPeriod,
        currentSubscriptionEndsAt,

        currentSubscriptionChangeRequest,
        currentSubscriptionPrice,
        currentSubscriptionProduct,
        currentSubscriptionPriceInterval,
        currentSubscriptionOnGracePeriod,
        currentSubscriptionIsCanceled,

        // Trial/Free plan details
        trialEndsAt,
        trialProduct,
        trialPrice,
    };
}

<script setup>
import Label from "@/Components/Form/Label.vue";
import {computed, ref, watch} from "vue";
import {debounce} from "lodash";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    TransitionRoot
} from '@headlessui/vue';
import {CheckIcon, ChevronUpDownIcon, XCircleIcon, XMarkIcon} from '@heroicons/vue/24/solid'
import {__, getColorForItem,} from "@/Utils";
import {InputError} from "@/Components/Form";
import useAttributesWithDusk from "@/Utils/useAttributesWithDusk.js";

const props = defineProps({
    label: String,
    form: Object,
    id: String,
    required: Boolean,
    options: Function | Array,
    defaultValue: [String, Number, Array, Object],
    displayLabelCallback: {
        type: Function,
        default: (model) => model ? (model.name || model.label || model.nice_name) : ''
    },
    optionsFilter: {
        type: Function,
        default: null
    },
    optionKey: {
        type: String,
        default: 'value'
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    dropdownPosition: {
        type: String,
        default: 'bottom'
    },
    clearable: {
        type: Boolean,
        default: true
    },
    placeholder: {
        type: String,
        default: ''
    },
    readonly: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    searchable: {
        type: Boolean,
        default: true,
    }
});

const queryInput = ref(null);
const options = ref([]);
const loading = ref(false);
const model = ref(props.form[props.id]);
const query = ref('');
const defaultApplied = ref(false);

const emits = defineEmits(['change']);

const loadOptionsFromPropsArray = () => {

    if (query.value === '') {
        options.value = props.options;
        return;
    }

    options.value = props.options.filter(props.optionsFilter || ((model) =>
            (model.name || model.label)
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(query.value.toLowerCase().replace(/\s+/g, ''))
            ||
            (model.badges && model.badges.some(badge => badge.label.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, ''))))
    ));
};

const loadOptionsFromCallback = debounce(async () => {
    loading.value = true;
    try {
        options.value = await props.options(query.value);

        if (!model.value && props.defaultValue && !props.multiple && !defaultApplied.value) {
            if (!options.value.filter(option => option[props.optionKey] === props.defaultValue[props.optionKey]).length) {
                options.value.push(props.defaultValue);
                selectCurrentOption(props.defaultValue);
                defaultApplied.value = true;
                return;
            }
        }

        if (model.value && typeof (model.value) === 'object') {
            if (!props.multiple) {
                if (!options.value.filter(option => option[props.optionKey] === model.value[props.optionKey]).length) {
                    options.value.push(model.value);
                }
            }
        }

        selectCurrentOption(model.value);
    } catch (error) {
        console.error(error);
    }
    loading.value = false;
}, 250);
const renderOptions = () => {
    if (typeof (props.options) === 'function') {
        loadOptionsFromCallback();
    } else {
        loadOptionsFromPropsArray();
        selectCurrentOption(model.value);
    }
}

defineExpose({renderOptions});

function reloadOptions(event) {
    query.value = event.target.value;
    renderOptions();
}

function focus() {
    queryInput.value.el.select();
}

function selectModelByKey(value) {
    if (props.multiple) {
        model.value.length = 0;
    } else {
        model.value = null
    }

    if (value || value === 0) {
        for (const option of options.value) {
            if (props.multiple) {
                if (value.filter(v => v.toString() === option[props.optionKey]?.toString()).length) {
                    model.value.push(option);
                }
            } else {
                if (option[props.optionKey]?.toString() === value.toString()) {
                    model.value = option;
                    break;
                }
            }
        }
    }

    if (props.multiple) {
        const owner = options.value.find(option => option.cantBeDeleted);
        if (owner) {
            if (!model.value || !model.value.find(option => option.cantBeDeleted)) {
                model.value.push(owner);
            }
        }
    }
}

function selectCurrentOption(newValue) {
    if (newValue === null || typeof (newValue) !== 'object') {
        selectModelByKey(newValue);
    } else {
        if (props.multiple) {
            selectModelByKey(newValue.map(value => value[props.optionKey]));
        } else {
            selectModelByKey(newValue ? newValue[props.optionKey] : null);
        }
    }
}

watch(model, model => {
    props.form[props.id] = model;
    props.form.clearErrors(props.id);
    // props.form.validate(props.id);
});

watch(() => props.form[props.id], (newValue) => {
    selectCurrentOption(newValue);
    emits('change', {key: props.id, value: newValue});
});


watch(() => props.options, newValue => {
    options.value = newValue;
    selectCurrentOption(model.value);
});

watch(() => props.options, newOptions => {
    options.value = newOptions;

    if (!model.value) {
        return;
    }

    if (props.multiple) {
        selectModelByKey(model.value.map(value => value[props.optionKey]));
    } else {
        selectModelByKey(model.value[props.optionKey]);
    }
});

watch(() => props.defaultValue, (newValue) => {
    if (newValue && !model.value) {
        selectCurrentOption(newValue);
    }
}, { immediate: true });

function removeSingleModel(toRemove) {
    model.value = model.value.filter(candidate => candidate !== toRemove);
}

function clearModel() {
    model.value = props.multiple ? [] : null;
    emits('change', {key: props.id, value: model.value});
}

const placeholder = computed(() => {

    if (props.multiple && model.value.length) {
        if (model.value.length === 1) {
            return __('1 item selected');
        }

        return __(':count items selected', {count: model.value.length});
    }

    return props.placeholder || __('Search...');
});

renderOptions();

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <div :dusk="$attrs.duskComponentId">
        <div class="flex justify-between">
            <Label
                :dusk="`${$attrs.duskComponentId}-label`"
                v-if="props.label" :value="props.label" :required :readonly/>
            <slot name="suffix"/>
        </div>
        <Combobox v-model="model" :multiple="props.multiple" :disabled="props.disabled">
            <div class="relative">
                <div class="flex space-x-3 items-center w-full">
                    <div
                        class="block w-full relative overflow-hidden"
                        :class="{
                            'focus-within:ring-2 focus-within:ring-purple-600': !readonly && !disabled,
                            'rounded-md': !props.multiple || !model.length,
                            'rounded-t-md ': props.multiple && model.length,
                        }">
                        <ComboboxButton
                            as="div"
                            class="flex items-center pr-2 border dark:bg-slate-700 cursor-pointer"
                            :class="{
                             'rounded-t-md': props.multiple,
                             'rounded-md': !props.multiple || !model.length,
                            'border-gray-300 dark:border-slate-600': !form.errors[id],
                            'border-red-300': form.errors[id],
                            'bg-gray-50 dark:bg-slate-900 opacity-80 hover:cursor-not-allowed': readonly || disabled}">
                            <ComboboxInput
                                v-bind="useAttributesWithDusk($attrs)"
                                class="w-full pr-10 border-transparent focus:border-transparent focus:ring-0 dark:bg-slate-700 rounded-md"
                                :class="{
                            'placeholder-red-300': form.errors[id],
                            'cursor-pointer': !searchable,
                            'cursor-auto': searchable,
                            'bg-gray-50 dark:bg-slate-900 opacity-80 hover:cursor-not-allowed': readonly || disabled
                        }"
                                :placeholder="placeholder || __('Search...')"
                                v-model="query"
                                @change="reloadOptions"
                                @click="focus"
                                ref="queryInput"
                                :readonly="readonly || !searchable"
                                :required="props.required && !props.multiple"
                                :displayValue="(model) => props.displayLabelCallback(model)"/>
                            <div
                                :dusk="`${$attrs.duskComponentId}-clear-select`"
                                v-show="((!props.multiple && model) || (props.multiple && model.length)) && !props.disabled && props.clearable"
                                class="bg-white px-2 py-2 absolute top-1/2 -translate-y-1/2 right-0 mr-7 cursor-pointer flex items-center text-gray-400 dark:bg-slate-700"
                                @click="clearModel()"
                                v-if="!readonly">
                                <XMarkIcon
                                    class="size-5 text-gray-400"/>
                            </div>

                            <ChevronUpDownIcon
                                class="size-6 text-gray-400 transform"
                                aria-hidden="true"/>
                        </ComboboxButton>
                    </div>
                </div>
                <div class="pt-2 px-2 border border-t-0 rounded-b-md bg-slate-100 dark:bg-slate-900"
                     :class="{
                            'border-gray-300 dark:border-slate-600': !form.errors[id],
                            'border-red-300': form.errors[id],
                            'bg-gray-50 dark:bg-slate-900 opacity-80 hover:cursor-not-allowed': readonly || disabled}"
                     v-if="props.multiple && model.length">
                    <ul :class="{'pointer-events-none opacity-80': readonly }"
                        :dusk="`${$attrs.duskComponentId}-selected-options`">
                        <li v-for="(singleModel) in model" :key="singleModel[props.optionKey]"
                            class="group inline-flex items-center mr-2 mb-2 duration-100 rounded-lg px-3 py-0.5 cursor-pointer"
                            :class="[{ 'pointer-events-none bg-gray-200': singleModel.cantBeDeleted },
                                        getColorForItem(singleModel[props.optionKey])]">
                            <span>{{ props.displayLabelCallback(singleModel) }}</span>
                            <XCircleIcon v-if="!readonly && !singleModel.cantBeDeleted"
                                         class="size-5 ml-1 group-hover:opacity-50 duration-100 dark:text-slate-100"
                                         @click="removeSingleModel(singleModel)"/>
                        </li>
                    </ul>
                </div>

                <TransitionRoot
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    @after-leave="query = ''">
                    <ComboboxOptions
                        :dusk="`${$attrs.duskComponentId}-options`"
                        v-if="!readonly && !disabled"
                        class="absolute w-full drop-shadow-xl dark:drop-shadow-2xl border border-gray-200 dark:border-gray-600 z-50 max-h-60 overflow-auto custom-scrollbar rounded-xl bg-white p-2 text-base sm:text-sm divide-y dark:bg-slate-800 dark:divide-slate-600"
                        :class="{
                            'top-full mt-2': props.dropdownPosition === 'bottom',
                            'bottom-full mb-2': props.dropdownPosition === 'top'
                        }">
                        <div
                            v-if="options?.length === 0 || query !== ''"
                            class="relative cursor-default select-none py-2 px-4 text-gray-700">

                            {{ __('Nothing found.') }}
                        </div>
                        <ComboboxOption
                            :disabled="readonly"
                            v-for="option in options"
                            :key="option[props.optionKey]"
                            :value="option"
                            v-slot="{ selected, active }">

                            <li
                                :dusk="`${$attrs.duskComponentId}-option-${option[props.optionKey]}`"
                                class="relative cursor-pointer select-none py-2 pl-10 pr-4 rounded-md hover:bg-blue-50 dark:hover:bg-slate-600"
                                :class="{'text-gray-500 pointer-events-none' : option.cantBeDeleted}">
                                    <span class="flex justify-between items-center">
                                      <slot :option="option" name="option">
                                          <span class="inline-block">
                                              {{
                                                  props.displayLabelCallback(option)
                                              }}
                                          </span>
                                          <span class="text-right">
                                              <span
                                                  v-if="option.badges"
                                                  v-for="badge in option.badges"
                                                  class="text-xs inline-block mr-1 mb-1 px-2 py-1 rounded"
                                                  :class="[badge.color || 'bg-blue-400 text-white']">
                                                  {{ badge.label || badge.name }}
                                              </span>
                                          </span>
                                      </slot>
                                    </span>

                                <span v-if="selected"
                                      class="absolute inset-y-0 left-0 flex items-center pl-3 text-teal-600">
                                      <CheckIcon class="h-5 w-5" aria-hidden="true"/>
                                    </span>
                            </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </TransitionRoot>
            </div>
        </Combobox>

        <InputError class="mt-2" :message="form.errors[id]"/>
    </div>
</template>

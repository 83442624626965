import {createConfirmDialog as libCreateConfirmDialog} from "vuejs-confirm-dialog";
import {ConfirmDialogStore} from "@/Stores";

function createConfirmDialog(component, initAttrs) {

    const {reveal, onConfirm, onCancel} = libCreateConfirmDialog(component,initAttrs);

    reveal();
    ConfirmDialogStore.value.opened = true;

    onCancel(() => {
        ConfirmDialogStore.value.opened = false;
    })

    onConfirm(() => {
        ConfirmDialogStore.value.opened = false;
    });

    return {
        reveal, onConfirm, onCancel
    };
};

export default createConfirmDialog;

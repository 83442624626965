import {onKeyDown} from "@vueuse/core";
import {router} from "@inertiajs/vue3";
import __ from "./translation.js";

const userAccounts = [
    {
        label: __('Login as super admin'),
        shortcut: '1',
        email: 'super-admin@bestreply.ai'
    },
    {
        label: __('Login as admin'),
        shortcut: '2',
        email: 'admin@bestreply.ai'
    },
    {
        label: __('Login as user'),
        shortcut: '3',
        email: 'user@bestreply.ai'
    }
]

export {userAccounts};

export default function () {

    onKeyDown(userAccounts.map(account => account.shortcut), (event) => {
        if (event.ctrlKey) {

            router.post(route('loginLinkLogin'), {
                email: userAccounts.filter(account => account.shortcut === event.key)[0].email,
                redirect_url: '/portal'
            });

            event.preventDefault();
        }
    });
};


const colors = [
    'bg-indigo-100 text-indigo-600 border border-indigo-600 dark:bg-indigo-800 dark:border-indigo-800 dark:text-indigo-100',
    'bg-purple-100 text-purple-600 border border-purple-600 dark:bg-purple-800 dark:border-purple-800 dark:text-purple-100',
];

function hashCode(str) {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
        let char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }
    return hash;
}

export default function getColorForItem(item) {
    const hash = Math.abs(hashCode(item));
    return colors[hash % colors.length];
}

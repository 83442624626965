import {computed, ref, watch} from "vue";
import {usePage} from "@inertiajs/vue3";
import axios from "axios";
import {driver} from "driver.js";
import "driver.js/dist/driver.css";
import {__} from "@/Utils/index.js";
import {HelpStore} from "@/Stores/index.js";

const steps = ref([]);

const hasSteps = computed(() => {
    return steps.value?.length > 0;
});

function helpPageId(page) {
    return `${page}-help`;
}

const mascotWrapper = document.createElement('div');
mascotWrapper.style.display = 'none';
mascotWrapper.id = 'help-mascot-wrapper';
mascotWrapper.className = 'fixed flex z-[20001]';
document.body.appendChild(mascotWrapper);

const mascotBubbleWrapper = document.createElement('div');
mascotWrapper.appendChild(mascotBubbleWrapper);

const mascotBubble = document.createElement('div');
mascotBubble.className = 'bg-white dark:bg-slate-900 p-5 rounded-xl shadow-lg w-[300px] xl:w-[500px]';
mascotBubbleWrapper.appendChild(mascotBubble);

const mascotImageWrapper = document.createElement('div');
mascotWrapper.appendChild(mascotImageWrapper);

const mascotImage = document.createElement('img');
mascotImage.src = '/images/mascot/hi.png';
mascotImage.className = 'h-500 mx-auto';
mascotImageWrapper.appendChild(mascotImage);

const mascotBubbleContent = document.createElement('div');
mascotBubbleContent.className = 'mb-4';
mascotBubble.appendChild(mascotBubbleContent);

const mascotBubbleFooter = document.createElement('div');
mascotBubbleFooter.className = 'flex justify-between';
mascotBubbleFooter.innerHTML = `
    <div>
        <span data-current-step></span>/<span data-steps></span>
    </div>
    <div class="flex items-center space-x-4">
        <button class="cursor-pointer" data-prev>${__('Previous')}</button>
        <button class="cursor-pointer" data-next>${__('Next')}</button>
    </div>`
mascotBubble.appendChild(mascotBubbleFooter);

const mascotCurrentStep = mascotBubbleFooter.querySelector('[data-current-step]');
const mascotTotalSteps = mascotBubbleFooter.querySelector('[data-steps]');
const prevButton = mascotBubbleFooter.querySelector('[data-prev]');
const nextButton = mascotBubbleFooter.querySelector('[data-next]');

prevButton.addEventListener('click', (event) => {
    event.preventDefault()
    HelpStore.driver.movePrevious();
});
nextButton.addEventListener('click', (event) => {
    event.preventDefault()
    HelpStore.driver.moveNext();
});

const driverInstance = driver({
    nextBtnText: __('Next >'),
    prevBtnText: __('< Previous'),
    doneBtnText: __('Done'),
    onNextClick: (element, step) => {
        if (step.onNext) {
            step.onNext().then(() => {
                driverInstance.moveNext();
            });
        } else {
            driverInstance.moveNext();
        }
    },
    onPrevClick: (element, step) => {
        if (step.onPrevious) {
            step.onPrevious().then(() => {
                driverInstance.movePrevious();
            });
        } else {
            driverInstance.movePrevious();
        }
    },
    disableActiveInteraction: true,
    onHighlightStarted: (element, step) => {
        if(step.mascot) {
            mascotWrapper.classList.add('fade-out');
        }
    },
    onPopoverRender: (element, options) => {
        mascotCurrentStep.innerHTML = options.state.activeIndex + 1;
        mascotTotalSteps.innerHTML = options.config.steps.length;

        if (options.state.activeIndex === 0) {
            prevButton.style.display = 'none';
        } else {
            prevButton.style.display = 'block';
        }

        if (options.state.activeIndex === options.config.steps.length - 1) {
            nextButton.innerHTML = options.config.doneBtnText;
        } else {
            nextButton.innerHTML = options.config.nextBtnText;
        }

        if (options.state.activeStep.mascot) {
            const mascot = options.state.activeStep.mascot;

            element.wrapper.classList.add('with-mascot');
            mascotWrapper.setAttribute('data-horizontal-position', mascot.horizontalPosition);
            mascotWrapper.setAttribute('data-vertical-position', mascot.verticalPosition);
            mascotWrapper.setAttribute('data-image-position', mascot.imagePosition || 'side');
            mascotImage.style.height = mascot.imageHeight || '500px';
        } else {
            element.wrapper.classList.remove('with-mascot');
        }
    },
    onHighlighted: (element, step) => {
        if (step.mascot) {
            mascotWrapper.style.display = 'flex';
            mascotWrapper.classList.remove('fade-out');
            mascotBubbleContent.innerHTML = `<h1 class="text-xl mb-2">${step.mascot.title}</h1><p>${step.mascot.description}</p>`;

            if (step.mascot.image) {
                mascotImage.src = step.mascot.image;
            } else {
                mascotImage.src = '/images/mascot/hi.png';
            }

            document.querySelector('svg.driver-overlay path').style.pointerEvents = 'none';
            document.body.classList.remove('driver-active');
        } else {
            mascotWrapper.style.display = 'none';
        }
    },
    onDestroyed: () => {
        mascotWrapper.style.display = 'none';
    },
    showProgress: true,
    steps: []
});

function showHelp() {

    steps.value.forEach((step) => {
        if(step.mascot?.image) {
            const image = new Image();
            image.src = step.mascot.image;
        }
    });

    driverInstance.setSteps(steps.value);
    driverInstance.drive();
    markAsRead(usePage().component);
}

function useSeenHelpPage(page) {
    return usePage().props.auth.flags.indexOf(helpPageId(page)) !== -1;
}

function autoStartHelp(page) {
    if (!hasSteps.value) {
        return false;
    }

    return !useSeenHelpPage(page);
}

watch(steps, () => {
    if (autoStartHelp(usePage().component)) {
        showHelp();
    }
});

function markAsRead(page) {
    if (!useSeenHelpPage(page)) {
        axios.post(route('portal.api.help.mark-as-read', {page: helpPageId(page)}));
    }
}

export default {
    steps,
    hasSteps,
    markAsRead,
    setSteps: (newSteps) => {
        steps.value = newSteps;
    },
    autoStartHelp,
    showHelp,
    driver: driverInstance
};

import {reactive} from "vue";
import axios from "axios";

const store = reactive({
    async getAuthenticableAccounts(query) {
        try {
            const response = await axios.get(route('portal.api.users.authenticable', {
                query
            }));

            return response.data.users;
        } catch (error) {
            console.error(error);
        }

        return false;
    }
});

export default store;

import {format, fromUnixTime} from 'date-fns';
import dayjs from "dayjs";
import {usePage} from "@inertiajs/vue3";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone.js";

dayjs.extend(timezone);
dayjs.extend(utc);

function isUnixTimestamp(str) {
    if (!/^\d+$/.test(str)) {
        return false;
    }
    const num = parseInt(str, 10);
    return !isNaN(num) && num > 0 && num < 2147483647;
}

export default function (date, formatStr = 'D MMM YYYY HH:mm') {

    if (!date) {
        return '-';
    }

    if (isUnixTimestamp(date)) {
        date = fromUnixTime(date);
        return format(date, formatStr);
    }

    const timezone = usePage().props.timezone;

    return dayjs.utc(date).tz(timezone).format(formatStr);
}

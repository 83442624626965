import {userAccounts} from "@/Utils/localEnvHelper.js";
import {router} from "@inertiajs/vue3";

let uiDebugBar;

function addCurrentComponent(page) {
    const currentComponent = uiDebugBar.querySelector('[data-current-component]') || document.createElement('div');
    currentComponent.innerText = page.component;
    currentComponent.setAttribute('data-current-component', '');
    currentComponent.className = 'rounded-full bg-black text-white text-xs px-2 py-1 color-black';

    uiDebugBar.appendChild(currentComponent);
}

function addUserSelector(page) {
    const userSelector = uiDebugBar.querySelector('[data-user-selector]') || document.createElement('div');
    userSelector.innerText = '👨‍ ' + (page.props.auth ? page.props.auth.name : 'Guest');
    userSelector.setAttribute('data-user-selector', '');
    userSelector.className = 'rounded-full bg-black text-white text-xs px-2 py-1 color-black cursor-pointer relative';

    const userSelectorDropdown = document.createElement('div');
    userSelectorDropdown.setAttribute('data-user-selector-dropdown', '');
    userSelectorDropdown.className = 'absolute bg-white text-black rounded-lg shadow-md p-2 bottom-full right-0 z-10 whitespace-nowrap space-y-2 py-2 px-5 hidden';
    userSelector.appendChild(userSelectorDropdown);

    for (const userAccount of userAccounts) {
        const loginLink = document.createElement('a');
        loginLink.className = 'block hover:underline';
        loginLink.innerText = `${userAccount.label} (Ctrl + ${userAccount.shortcut})`;
        loginLink.addEventListener('click', (ev) => {
            ev.preventDefault();
            router.post(route('loginLinkLogin'), {
                redirect_url: route('portal.dashboard'),
                email: userAccount.email
            });
        })

        userSelectorDropdown.appendChild(loginLink);
    }

    userSelector.addEventListener('click', (ev) => {
        if (ev.target.closest('[data-user-selector-dropdown]')) {
            return;
        }

        userSelectorDropdown.classList.toggle('hidden');
    });

    uiDebugBar.appendChild(userSelector);
}

export default function renderDebugMessage(page) {
    if (!page.props.enable_test_accounts) {
        return;
    }
    uiDebugBar = document.getElementById('ui-debug-bar');
    if (!uiDebugBar) {
        return;
    }

    uiDebugBar.style.display = 'flex';

    addCurrentComponent(page);
    addUserSelector(page);
}

<script setup>
import {computed} from "vue";

const props = defineProps({
    loading: Boolean,
    disabled: Boolean,
    icon: {
        type: [Object, Function],
        default: null
    },
    iconSize: {
        type: String,
        default: 'size-5'
    },
    buttonStyle: {
        type: String,
        default: 'primary'
    },
});

const buttonStyleClasses = computed(() => {
    switch (props.buttonStyle) {
        case 'secondary':
            return 'text-blue-600 hover-visible:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600';
        case 'neutral':
            return 'text-gray-600 hover-visible:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 dark:text-slate-400 dark:hover:text-slate-600';
        case 'danger':
            return 'text-red-600 dark:text-red-300 hover-visible:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600';
        case 'danger-border':
            return 'text-red-600 border border-red-600 hover-visible:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 font-semibold';

        case 'profile-billing-success':
            return '4xl:!py-0 text-green-600 hover-visible:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600';
        case 'profile-billing':
            return '4xl:!py-0 4xl:!leading-6 text-red-600 border border-red-600 hover-visible:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 font-semibold';

        case 'compact-success':
            return '4xl:!py-0 4xl:!leading-6 text-green-600 border border-green-600 hover-visible:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 font-semibold';

        case 'compact-danger':
            return '4xl:!py-0 4xl:!leading-6 text-red-600 border border-red-600 hover-visible:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 font-semibold';

        case 'success':
            return 'text-green-600 hover-visible:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600';
        case 'primary':
        default:
            return 'text-purple-600 dark:text-purple-400 hover-visible:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600';
    }
});

</script>
<template>
    <button
        :class="[loading ? 'opacity-25' : '', buttonStyleClasses]"
        :disabled="loading || disabled"
        type="button"
        class="flex items-center justify-center space-x-2 px-3.5 py-2.5 rounded-full hover-visible:text-white disabled:opacity-50 disabled:cursor-not-allowed">
        <component v-if="icon" :is="icon" class="mr-2" :class="iconSize"/>
        <slot/>
    </button>
</template>

<template>
    <transition name="page" mode="out-in">
        <slot/>
    </transition>
</template>
<style>
.page-enter-active,
.page-leave-active {
    transition: opacity 0.075s, transform 0.075s;
}

.page-enter-from {
    opacity: 0;
    transform: translateY(-10px);
}

.page-enter-to {
    opacity: 1;
    transform: scale(1);
}

.page-leave-active {
    opacity: 1;
    transform: scale(1);
}

.page-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}
</style>

import {marked} from "marked";
import DOMPurify from "dompurify";

const renderer = new marked.Renderer();
renderer.link = (e) => {
    return `<a href="${e.href}" target="_blank" rel="noopener noreferrer"${e.title ? ` title="${e.title}"` : ''}>${e.text}</a>`;
};
marked.setOptions({
    renderer: renderer
});

export default function (input) {

    const cleanHtml = DOMPurify.sanitize(input, { ALLOWED_TAGS: ['img'] });

    return marked.parse(cleanHtml);
}

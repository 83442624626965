<script setup>
import {ref, watch} from 'vue'
import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'

const props = defineProps({
    id: String,
    modelValue: Boolean,
    disabled: Boolean,
    centerLabel: {
        type: Boolean,
        default: true
    },
    size: {
        type: String,
        default: 'normal'
    }
});
const emits = defineEmits(['update:modelValue', 'change']);
const enabled = ref(props.modelValue);

watch(() => props.modelValue, value => {
    enabled.value = value;
});

watch(enabled, value => {
    emits('update:modelValue', value);
});
</script>

<template>
    <SwitchGroup as="div" :class="[props.centerLabel ? 'items-center' : 'items-start', 'flex pointer-events-none']">
        <Switch v-model="enabled" @click="$emit('change', !enabled)"
                class="relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2"
                :class="{
                'mt-1': !props.centerLabel,
                'bg-purple-600': enabled,
                'bg-gray-400 dark:bg-gray-700': !enabled,
                'opacity-60 pointer-events-none': props.disabled,
                'pointer-events-auto': !props.disabled,
                'h-5 w-9': props.size === 'normal',
                'h-4 w-8': props.size !== 'normal'}">
                            <span
                                :class="[enabled ? 'translate-x-4' : 'translate-x-0', props.size === 'normal' ? 'h-4 w-4' : 'h-3 w-3', 'pointer-events-none relative inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                          <span
                              :class="[enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']"
                              aria-hidden="true">
                            <svg class="text-gray-400 dark:text-gray-700" fill="none" viewBox="0 0 12 12"
                                 :class="[props.size === 'normal' ? 'h-3 w-3': 'h-2 w-2']">
                              <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </span>
                          <span
                              :class="[enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']"
                              aria-hidden="true">
                            <svg class="text-purple-600" :class="[props.size === 'normal' ? 'h-3 w-3': 'h-2 w-2']"
                                 fill="currentColor" viewBox="0 0 12 12">
                              <path
                                  d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"/>
                            </svg>
                          </span>
                        </span>
        </Switch>
        <SwitchLabel as="div" class="ml-3 cursor-pointer" :class="size === 'normal' ? 'text-base' : 'text-sm'">
      <span class="block cursor-pointer">
        <slot name="label"/>
      </span>
            <span class="block text-gray-500 dark:text-gray-400 cursor-pointer">
        <slot name="description"/>
      </span>
        </SwitchLabel>
    </SwitchGroup>
</template>

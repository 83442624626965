import {usePage} from "@inertiajs/vue3";
import dayjs from "dayjs";

export default function (date) {

    const timezone = usePage().props.timezone;

    if (!date) {
        return '-';
    }

    const inputDate = typeof (date) === 'string' ? new Date(date) : date;

    return dayjs.utc(inputDate).tz(timezone).format('D MMM YYYY');
}

<script setup>
import {Bars3Icon} from "@heroicons/vue/24/outline/index.js";
import {Sidebar} from "@/Stores";
import BreadcrumbNav from "@/Layouts/Partials/Portal/BreadcrumbNav.vue";

const sidebarOpen = Sidebar.sidebarOpen;

</script>
<template>
    <div
        class="flex h-16 shrink-0 items-center gap-x-4 px-4 sm:gap-x-6 sm:px-2 lg:px-6 z-[1]">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden dark:text-slate-200 md:ml-0" @click="sidebarOpen = true">
            <span class="sr-only">Open sidebar</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true"/>
        </button>
        <div class="flex-1 pr-4 flex justify-end">
            <div class="lg:flex-1 lg:flex hidden">
                <BreadcrumbNav/>
            </div>
            <div class="ml-4 flex items-center md:ml-6 space-x-3">
                <div id="page-header-actions"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {ThemeStore} from "@/Stores/index.js";

const url = computed(() => {
    return ThemeStore.isDark() ? '/images/logo-large-dark.svg' : '/images/logo-large.svg';
});

</script>
<template>
    <img :src="url" alt="BestReply" class="h-[54px]"/>
</template>

<template>
    <Transition
        enter-active-class="transition ease-in-out"
        enter-from-class="translate-x-0"
        leave-active-class="transition ease-in-out"
        leave-to-class="-translate-x-full"
    >
        <slot/>
    </Transition>
</template>

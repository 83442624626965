import {usePage} from "@inertiajs/vue3";

export default function useAbilities() {

    function hasAbility(ability) {
        const page = usePage();

        return page.props.auth.abilities.find(a => a.name === ability)?.value;
    }

    return {hasAbility};
}

export function usePermissions() {

    function can(groupPermission) {
        const page = usePage();
        let [group, permission] = groupPermission.split(".");

        return page.props.permissions?.[group]?.[permission] || false;
    }

    return {can};
}

<script setup>
import {Label} from "@/Components/Form";
import {ref, watch} from "vue";
import {debounce} from "lodash";
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    ComboboxButton,
    TransitionRoot
} from '@headlessui/vue';
import {CheckIcon, ChevronDownIcon} from '@heroicons/vue/24/outline'
import useAttributesWithDusk from "@/Utils/useAttributesWithDusk.js";

const props = defineProps({
    label: String,
    id: String,
    required: Boolean,
    modelValue: Object,
    options: Function | Array,
    disabled: Boolean,
    readonly: Boolean,
    placeholder: {
        type: String,
        default: ''
    },
    searchable: {
        type: Boolean,
        default: true
    },
    clearable: {
        type: Boolean,
        default: true
    },
    displayLabelCallback: {
        type: Function,
        default: (model) => model ? (model.label || model.name) : ''
    },
    optionsFilter: {
        type: Function,
        default: null
    },
    optionKey: {
        type: String,
        default: 'id'
    },
    bgColor: {
        type: String,
        default: 'dark:bg-gray-800'
    },
    height: {
        type: String,
        default: 'h-8 md:h-10'
    }
});

const queryInput = ref(null);
const selectOptions = ref([]);
const loading = ref(false);
const model = ref(props.modelValue);
const query = ref('');

const emit = defineEmits(['update:modelValue'])

const loadOptionsFromPropsArray = () => {

    if (query.value === '') {
        selectOptions.value = props.options;
        return;
    }

    selectOptions.value = props.options.filter(props.optionsFilter || ((model) => (model.name || model.label)
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, '')))
    );
};

const loadOptionsFromCallback = debounce(async () => {
    loading.value = true;
    try {
        selectOptions.value = await props.options(query.value);
        selectCurrentOption(model.value);
    } catch (error) {
        console.error(error);
    }
    loading.value = false;
}, 250);

const renderOptions = () => {
    if (typeof (props.options) === 'function') {
        loadOptionsFromCallback();
    } else {
        loadOptionsFromPropsArray();
        selectCurrentOption(model.value);
    }
}

function reloadOptions(event) {
    query.value = event.target.value;
    renderOptions();
}

function focus() {
    queryInput.value.el.select();
}

function selectModelByKey(value) {
    if (value) {
        for (const option of selectOptions.value) {
            if (option[props.optionKey]?.toString() === value.toString()) {
                model.value = option;
                return;
            }
        }
    }
    model.value = null;
}

function selectCurrentOption(newValue) {
    if (newValue === null || typeof (newValue) !== 'object') {
        selectModelByKey(newValue);
    } else {
        selectModelByKey(newValue ? newValue[props.optionKey] : null);
    }
}

watch(model, model => {
    emit('update:modelValue', model)
});

watch(() => props.modelValue, newValue => {
    selectCurrentOption(newValue);
    query.value = props.displayLabelCallback(newValue);
});

watch(() => props.options, options => {
    selectOptions.value = options;

    if (!model.value) {
        return;
    }

    selectModelByKey(model.value[props.optionKey]);
});

renderOptions();

defineExpose({
    focus: () => {
        queryInput.value.el.focus()
    }
})

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <div :dusk="$attrs.duskComponentId">
        <Label
            :dusk="`${$attrs.duskComponentId}-label`"
            :label="props.label"
            :id="props.id"
            :required="props.required"
            :disabled="props.disabled"
            class="flex flex-col mb-4 last:mb-0">
            <Combobox v-model="model" :disabled="props.disabled">
                <div class="relative">
                    <div
                        :class="[props.bgColor, height, !props.readonly && !props.disabled ? 'focus-within:ring-1 focus-within:ring-purple-600 focus-within:border-transparent' : '', 'bg-white relative w-full cursor-default overflow-hidden text-left sm:text-sm flex items-center rounded-md justify-between border border-gray-300 dark:border-slate-600']">
                        <ComboboxButton
                            as="div"
                            class="w-full flex items-center cursor-pointer"
                            :class="{
                            'hover:cursor-not-allowed': props.readonly || props.disabled
                        }"
                        >
                            <ComboboxInput
                                class="text-gray-950 dark:text-gray-300 bg-white flex-grow w-full truncate border-transparent rounded-md shadow-sm placeholder-gray-400 disabled:opacity-50 read-only:opacity-50 focus:outline-none focus:ring-0 focus:border-transparent"
                                :class="[props.bgColor, height, (props.readonly || props.disabled) ? 'opacity-80 hover:cursor-not-allowed' : '', props.searchable ? 'cursor-auto' : 'cursor-pointer']"
                                v-model="query"
                                @change="reloadOptions"
                                @focus="focus"
                                v-bind="useAttributesWithDusk($attrs)"
                                ref="queryInput"
                                :required="props.required"
                                :disabled="props.disabled"
                                :readonly="props.readonly || !props.searchable"
                                :placeholder="props.placeholder"
                                :displayValue="(model) => props.displayLabelCallback(model)"/>

                            <div v-show="model && !props.disabled && !props.readonly && props.clearable"
                                 class="absolute top-1/2 -translate-y-1/2 right-0 mr-10 cursor-pointer flex items-center pb-0.5 text-gray-500 dark:text-gray-400"
                                 @click="model = null">
                                x
                            </div>
                            <ChevronDownIcon class="size-5 text-gray-500 dark:text-gray-400 mr-3" aria-hidden="true"/>
                        </ComboboxButton>
                    </div>

                    <TransitionRoot
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        @after-leave="query = ''">
                        <ComboboxOptions
                            :dusk="`${$attrs.duskComponentId}-options`"
                            v-if="!props.readonly && !props.disabled"
                            class="absolute top-full mt-2 w-full drop-shadow-xl dark:drop-shadow-2xl focus:outline-none z-50 max-h-60 overflow-auto rounded-md border border-indigo-500 dark:border-slate-700 bg-white dark:bg-slate-900 p-1">
                            <div
                                v-if="selectOptions.length === 0 && query !== ''"
                                class="relative cursor-default select-none py-2 px-4 text-gray-700">
                                {{ __('Nothing found.') }}
                            </div>
                            <ComboboxOption
                                :dusk="`${$attrs.duskComponentId}-option-${option[props.optionKey]}`"
                                v-for="option in selectOptions"
                                :key="option[props.optionKey]"
                                :value="option"
                                v-slot="{ selected, active }">
                                <li
                                    class="relative cursor-default select-none py-2 pl-8 pr-4 rounded-md text-gray-900 dark:text-gray-300 hover:bg-indigo-200"
                                    :class="{'bg-purple-100 dark:bg-slate-700': active}">
                                    <span
                                        class="block truncate text-sm md:text-base"
                                        :class="{ 'font-medium': selected, 'font-normal': !selected }">
                                      <slot :option="option" name="option">
                                          {{
                                              props.displayLabelCallback(option)
                                          }}
                                      </slot>
                                    </span>
                                    <span
                                        v-if="selected"
                                        class="absolute inset-y-0 left-0 flex items-center pl-2 text-teal-600">
                                      <CheckIcon class="h-4 w-4 md:h-5 md:w-5" aria-hidden="true"/>
                                    </span>
                                </li>
                            </ComboboxOption>
                        </ComboboxOptions>
                    </TransitionRoot>
                </div>
            </Combobox>
        </Label>
    </div>
</template>

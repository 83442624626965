<script setup>
import {usePage, Link} from "@inertiajs/vue3";
import {ChevronDoubleRightIcon} from "@heroicons/vue/24/solid";

const page = usePage();

function isLastIndex(index) {
    return index === page.props.breadcrumbNav.length - 1;
}
</script>
<template>
    <div class="flex items-center space-x-3 text-sm" dusk="breadcrumb-nav">
        <div
            class="flex items-center space-x-3"
            v-for="(breadcrumb, index) of page.props.breadcrumbNav"
        >
            <Link
                :href="breadcrumb.link"
                :class="{
                      'text-purple-600 dark:text-purple-400 cursor-default': isLastIndex(index),
                      'hover:underline text-gray-900 dark:text-slate-200': !isLastIndex(index),
                    }"
            >
                {{ breadcrumb.label }}
            </Link>
            <ChevronDoubleRightIcon
                v-if="!isLastIndex(index)"
                class="h-4  text-gray-600 dark:text-slate-200"
            />
        </div>
    </div>
</template>

<script setup>
import {Link, usePage} from "@inertiajs/vue3";

const props = defineProps({
    item: Object,
});

const page = usePage();

function currentPage(item) {
    return item.id === page.props.currentPageId;
}

</script>

<template>
    <ul v-if="item.children" class="space-y-1">
        <li v-for="(subitem, index) in item.children" class="space-y-2">
            <Link :href="subitem.url"
                  :class="[currentPage(subitem) ? 'bg-gray-50 text-purple-400 dark:bg-slate-700' : 'text-gray-700 hover:text-purple-700 hover:bg-purple-200 dark:text-slate-200 dark:hover:text-white dark:hover:bg-purple-400', 'group flex gap-x-3 rounded-md px-2 text-sm leading-6']">

                <div
                    class="fi-sidebar-item-grouped-border relative flex w-6 items-center justify-center">
                    <div class="absolute w-px bg-gray-300 dark:bg-slate-200"
                         :class="{'-bottom-1/2 top-1/2': index === 0,
                                  'bottom-1/2 -top-1/2': index === item.children.length - 1,
                                  'bottom-0 top-0': index > 0 && index < item.children.length - 1,
                                  }"
                    ></div>
                    <div
                        class="relative h-1.5 w-1.5 rounded-full z-10"
                        :class="{'bg-gray-300 dark:bg-slate-200': !currentPage(subitem),
                                 'bg-purple-600 dark:bg-purple-200': currentPage(subitem),
                                 }"
                    ></div>
                </div>
                <span class="block py-2">{{ subitem.label }}</span>
            </Link>
        </li>
    </ul>
</template>

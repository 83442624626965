<script setup>
import {__} from "@/Utils/index.js";
import {ref} from "vue";
import {usePage} from "@inertiajs/vue3";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {isSameDay} from "date-fns";
import {Button, ButtonTransparent, LinkButton} from "@/Components/Common";
import {XMarkIcon} from "@heroicons/vue/24/outline";

const showModal = ref(false);

const today = new Date();
const page = usePage();
const tokenUsage = page.props.account.token_usage;

if (hasUsedToManyTokens() && !didNotSeeModalToday()) {
    showModal.value = true;
    localStorage.setItem('token_usage_warning_modal_last_seen', today)
}

function hasUsedToManyTokens()
{
    return (tokenUsage.credit_usage - tokenUsage.time_passed_in_subscription) > page.props.settings.usage_warning_diff_between_time_and_tokens;
}

function didNotSeeModalToday()
{
    const storageLastSeen = localStorage.getItem('token_usage_warning_modal_last_seen');

    if (!storageLastSeen) {
        return false;
    }

    const lastSeen = new Date(storageLastSeen);

    return isSameDay(today, lastSeen);
}

const closeModal = () => {
    showModal.value = false;
};

</script>
<template>
    <TransitionRoot appear :show="showModal" as="template" @keydown.esc="closeModal">
        <Dialog as="div" @close="closeModal" class="relative z-10">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black/25"/>
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="w-full relative max-w-md transform overflow-hidden rounded-md bg-white p-5 text-left align-middle shadow-xl transition-all"
                        >
                            <div class="absolute right-0 top-0 hidden pr-2 pt-2 sm:block">
                                <button type="button"
                                        class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                        @click.capture="closeModal">
                                    <span class="sr-only">{{ __('Close') }}</span>
                                    <XMarkIcon class="size-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <DialogTitle
                                as="h3"
                                class="font-bold leading-6 text-gray-900"
                            >
                                {{ __('Usage warning') }}
                            </DialogTitle>
                            <div class="mt-3 text-sm text-gray-500 flex flex-col">
                                    <span>{{ __('You are running out of tokens!') }}</span>
                                    <span>{{
                                        __('It looks like you have used :tokensUsed% of your tokens in :timePeriodPercent% of the billing period. ' +
                                            'In this rate you are going to run out of tokens before :endPeriodSubscription.', {
                                            tokensUsed: tokenUsage.credit_usage,
                                            timePeriodPercent: tokenUsage.time_passed_in_subscription,
                                            endPeriodSubscription: formatDate(tokenUsage.subscription_end_date),
                                        })
                                    }}</span>
                            </div>
                            <div class="flex mt-4 space-x-2 justify-end">
                                <ButtonTransparent
                                    button-style="neutral"
                                    class="font-normal text-sm"
                                    @click="closeModal"
                                >
                                    {{ __('Remind me later') }}
                                </ButtonTransparent>
                                <LinkButton
                                    class="text-sm"
                                    :href="route('portal.payment-plans.choose')"
                                >
                                    {{ __('Buy more tokens') }}
                                </LinkButton>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

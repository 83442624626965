import {__} from "@/Utils";
import {
    ChatBubbleLeftIcon,
    CircleStackIcon,
    Cog6ToothIcon,
    CommandLineIcon,
    DocumentChartBarIcon,
    InboxStackIcon, PhoneIcon,
    Squares2X2Icon,
    TableCellsIcon,
    UsersIcon,
    ClipboardDocumentListIcon, GlobeAltIcon
} from "@heroicons/vue/24/outline";
import {route} from "ziggy-js";
import {useAbilities, usePermissions} from "@/Utils";
import {usePage} from "@inertiajs/vue3";

export default function PageNavigation() {
    const {hasAbility} = useAbilities();
    const {can} = usePermissions();
    const page = usePage();
    const auth = page.props.auth;

    const navigation = {
        portal: [
            {
                id: 'dashboard',
                label: __('Dashboard'),
                url: route('portal.dashboard'),
                icon: Squares2X2Icon,
                visible: hasAbility('modules.dashboard'),
            },
            {
                id: 'chat',
                label: __('Chat'),
                url: route('portal.chat'),
                icon: ChatBubbleLeftIcon,
                visible: hasAbility('modules.chat'),
            },
            {
                id: 'training',
                label: __('Training'),
                icon: TableCellsIcon,
                children: [
                    {
                        id: 'training_models',
                        label: __('AI Models'),
                        url: route('portal.training-models.index'),
                        icon: TableCellsIcon,
                        visible: hasAbility('modules.training-model'),
                    },
                    {
                        id: 'training_data',
                        label: __('Train AI Model'),
                        url: route('portal.training-data.index'),
                        icon: CircleStackIcon,
                        visible: hasAbility('modules.training-data'),
                    },
                    {
                        id: 'tags',
                        label: __('Tags'),
                        url: route('portal.tags.index'),
                        icon: DocumentChartBarIcon,
                        visible: hasAbility('modules.training-tags'),
                    },
                ]
            },
            {
                id: 'prompts',
                label: __('Prompts'),
                url: route('portal.prompts.index', {system_prompt_type: 'email_summary'}),
                icon: CommandLineIcon,
                visible: hasAbility('modules.prompts'),
            },
            {
                id: 'inboxes',
                label: __('Inboxes'),
                url: route('portal.inbox.index'),
                icon: InboxStackIcon,
                visible: hasAbility('modules.inboxes'),
            },
            {
                id: 'users',
                label: __('Users'),
                url: route('portal.users.index'),
                icon: UsersIcon,
                visible: hasAbility('modules.users'),
            },
            {
                id: 'phone-numbers',
                label: __('Phone Numbers'),
                url: route('portal.phone-numbers.index'),
                icon: PhoneIcon,
                visible: hasAbility('modules.voice-assistant'),
            },
            {
                id: 'websites',
                label: __('Websites'),
                url: route('portal.websites.index'),
                icon: GlobeAltIcon,
                visible: hasAbility('modules.website-chat'),
            },
            {
                id: 'admin',
                label: __('Admin'),
                url: '/admin',
                icon: Cog6ToothIcon,
                visible: auth.role === 'super_admin',
            },
            {
                id: 'faq',
                label: __('FAQ'),
                url: route('portal.faq'),
                icon: ClipboardDocumentListIcon,
                visible: hasAbility('modules.faq'),
            },
        ]
    };

    return {
        portalNavigation: navigation.portal.filter(item => {
            if (item.children) {
                item.children = item.children.filter(child => child.visible);
                if (!item.children.length) {
                    return;
                }
                item.url = item.children[0].url;
                return item;
            }
            return item.visible;
        })
    }
}






import {reactive} from "vue";
import axios from "axios";
import {route} from "ziggy-js";

const store = reactive({
    async get(training_model) {

        try {
            const response = await axios.get(route('portal.api.model-training-info', {
                training_model: training_model
            }));

            return response.data;
        } catch (error) {
            console.error(error);
        }

        return false;
    }
});

export default store;

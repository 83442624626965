<script setup>
import {computed} from "vue";
import {router} from "@inertiajs/vue3";

const props = defineProps({
    href: {
        type: String,
        required: true
    },
    icon: {
        type: [Object, Function],
        default: null
    },
    buttonStyle: {
        type: String,
        default: 'primary'
    },
    newTab: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    }
});

const buttonStyleClasses = computed(() => {
    let linkStyle = '';

    if (props.disabled) {
        linkStyle = 'hover:cursor-not-allowed opacity-50';
    } else {
        linkStyle = 'hover:cursor-pointer';
    }

    switch (props.buttonStyle) {
        case 'secondary':
            linkStyle += ' bg-blue-600 hover:bg-blue-500';
            break;
        case 'neutral':
            linkStyle += ' bg-gray-600 hover:bg-gray-500';
            break;
        case 'danger':
            linkStyle += ' bg-red-600 hover:bg-red-500';
            break;
        case 'primary':
        default:
            linkStyle += ' bg-purple-600 hover:bg-purple-500';
    }

    return linkStyle;
});

function click() {
    if (props.disabled) {
        return;
    }

    if (props.newTab) {
        window.open(props.href);
    } else {
        router.visit(props.href);
    }
}

</script>

<template>
    <a
        :href
        @click.prevent="click"
        :class="buttonStyleClasses"
        class="px-3.5 py-2.5 inline-flex tracking-wide text-center text-white items-center rounded-full text-base focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 duration-75 transition-all">
        <component v-if="icon" :is="icon" class="size-5 text-white mx-3 md:mx-1"/>
        <span :class="{'hidden md:inline pl-2' : icon}">
            <slot/>
        </span>
    </a>
</template>

<script setup>
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import {XMarkIcon} from "@heroicons/vue/24/outline";
import {Select} from "@/Components/Inputs";
import {nextTick, onMounted, ref} from 'vue';
import {UserStore} from "@/Stores";
import {ButtonTransparent, Button} from "@/Components/Common";

const props = defineProps({
    title: String,
});

const isOpen = ref(true);
const user = ref(null);
const userSelect = ref(null);

const emit = defineEmits(["confirm", "cancel"]);

async function users(query) {
    return UserStore.getAuthenticableAccounts(query);
}

function closeModalWith(value, user) {
    isOpen.value = false;
    emit(value, user);
}

function userLabel(user) {
    if(!user) {
        return '';
    }

    if(user.children?.length) {
        return `${user.name} (${user.children.join(', ')}, ${user.email})`;
    }

    return `${user.name} (${user.email})`;
}

onMounted(async () => {
    await nextTick();
    userSelect.value.focus();
});

</script>

<template>

    <TransitionRoot :show="isOpen" as="template">
        <Dialog as="div" class="relative z-50" @close="closeModalWith('cancel')">

            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                             leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-opacity-75 bg-black/50 transition-all backdrop-blur-sm"/>
            </TransitionChild>

            <div class="fixed inset-0 z-50 overflow-y-auto">
                <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                                     enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                     enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                     leave-from="opacity-100 translate-y-0 sm:scale-100"
                                     leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform rounded-lg bg-gray-100 text-slate-900 dark:text-slate-700 dark:bg-gray-700 px-4 pt-5 pb-4 text-left shadow transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <form>
                                <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button type="button"
                                            class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring focus:ring-indigo-500"
                                            @click="closeModalWith('cancel')">
                                        <span class="sr-only">Close</span>
                                        <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div class="sm:flex sm:items-center">
                                    <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                        <DialogTitle as="h3"
                                                     class="text-lg font-medium leading-6 dark:text-slate-200 text-black">
                                            {{ title || __('Select a user') }}
                                        </DialogTitle>
                                    </div>
                                </div>
                                <div class="mt-5">
                                    <Select ref="userSelect" v-model="user" :options="users" :display-label-callback="userLabel"/>
                                </div>
                                <div class="mt-5 flex sm:justify-end justify-between space-x-8">
                                    <ButtonTransparent
                                            type="button"
                                            @click.capture="closeModalWith('cancel')">
                                        {{ __('Cancel') }}
                                    </ButtonTransparent>
                                    <Button :disabled="!user"
                                            @click.capture="closeModalWith('confirm', user)">
                                        {{ __('Select') }}
                                    </Button>
                                </div>
                            </form>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

import {reactive} from "vue";
import axios from "axios";

const store = reactive({
    async get(query, account_id) {
        try {
            const response = await axios.get(route('portal.api.tags', {
                query: query,
                account_id: account_id
            }));

            return response.data;
        } catch (error) {
            console.error('Error loading tags:', error);
        }

        return false;
    }
});

export default store;

<script setup>
import { __, formatNumber } from "@/Utils/index.js";
import { PageNavigation, ThemeStore, Sidebar } from "@/Stores/index.js";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import { Avatar, ProgressBar, UserInfo } from "@/Components/Common/index.js";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { Link, usePage } from "@inertiajs/vue3";
import { MoonIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon, ArrowRightStartOnRectangleIcon } from "@heroicons/vue/20/solid";
import Submenu from "@/Layouts/Partials/Submenu.vue";
import EnvironmentBadge from "@/Layouts/Partials/EnvironmentBadge.vue";
import {ref, watch} from "vue";
import { Switch } from "@/Components/Inputs/index.js";
import TimeCounter from "@/Components/Common/TimeCounter.vue";
import TokenProgressBar from "@/Components/TokenProgressBar.vue";
import useCurrentSubscription from "@/Utils/useCurrentSubscription.js";

const sidebarOpen = Sidebar.sidebarOpen;
const darkMode = ref(ThemeStore.isDark());
const page = usePage();
const portalNavigation = ref(PageNavigation().portalNavigation);

const userNavigation = [];

const {trialProduct} = useCurrentSubscription();

if (page.props.impersonating) {
    userNavigation.push({ name: __('Stop impersonating'), href: route('portal.api.users.impersonate.stop') });
} else {
    userNavigation.push({ name: __('Log Out'), href: route('logout'), method: 'post' });
}

function currentPage(item) {
    return item.id === page.props.currentPageId;
}

function currentParentPage(item) {
    return item.children && item.children.some(subitem => subitem.id === page.props.currentPageId);
}

watch(() => page.props.enabled_facilities, () => {
    portalNavigation.value = PageNavigation().portalNavigation;
});

</script>

<template>
    <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
            <TransitionChild as="template" enter="transition-opacity ease-linear duration-300"
                             enter-from="opacity-0" enter-to="opacity-100"
                             leave="transition-opacity ease-linear duration-300"
                             leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-900/80"/>
            </TransitionChild>

            <div class="fixed inset-0 flex">
                <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                                 enter-from="-translate-x-full" enter-to="translate-x-0"
                                 leave="transition ease-in-out duration-300 transform"
                                 leave-from="translate-x-0" leave-to="-translate-x-full">
                    <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                        <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                                         enter-to="opacity-100" leave="ease-in-out duration-300"
                                         leave-from="opacity-100" leave-to="opacity-0">
                            <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                                <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                                    <span class="sr-only">{{ __('Close sidebar') }}</span>
                                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                                </button>
                            </div>
                        </TransitionChild>

                        <div class="flex grow flex-col gap-y-5 bg-white px-6 pb-4 overflow-y-auto dark:bg-slate-800">
                            <div class="flex h-16 shrink-0 items-center">
                                <Link class="flex items-center mt-2 space-x-5" :href="route('portal.dashboard')">
                                    <ApplicationLogo/>
                                </Link>
                            </div>

                            <EnvironmentBadge/>

                            <nav class="flex flex-1 flex-col">
                                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                                    <li>
                                        <ul role="list" class="-mx-2 space-y-1">
                                            <li v-for="item in portalNavigation" :key="item.id" class="space-y-1">
                                                <Link :href="item.url"
                                                      :class="[currentPage(item) || currentParentPage(item) ? 'bg-purple-100 text-purple-600 dark:bg-purple-600 dark:text-slate-200' : 'text-gray-900 hover:text-purple-600 hover:bg-purple-50 dark:text-slate-200 dark:hover:text-purple-200 dark:hover:bg-purple-500', 'group flex gap-x-3 rounded-md p-2 leading-6']">
                                                    <component v-if="item.icon" :is="item.icon"
                                                               :class="[currentPage(item) || currentParentPage(item) ? 'text-purple-600 dark:text-slate-200' : 'text-gray-400 group-hover:text-purple-600 dark:group-hover:text-purple-200 dark:text-slate-400', 'h-6 w-6 shrink-0']"
                                                               aria-hidden="true"/>
                                                    <span>{{ item.label }}</span>
                                                </Link>
                                                <Submenu :item="item"/>
                                            </li>
                                            <div class="flex py-1 pl-2 mt-5">
                                                <div class="flex flex-1 space-x-3">
                                                    <MoonIcon class="h-5 w-5 text-gray-400 dark:text-slate-400"/>
                                                    <div class="text-gray-700 dark:text-white">{{ __('Dark mode') }}</div>
                                                </div>
                                                <Switch v-model="darkMode" @click.prevent="ThemeStore.toggle()"/>
                                            </div>
                                        </ul>
                                    </li>

                                    <li class="mt-auto">
                                        <TimeCounter
                                            v-if="page.props.account.on_generic_trial && !trialProduct.is_free_plan"
                                            :label="__('Trial ends in:')"
                                            :timezone="$page.props.timezone"
                                            :start-at="$page.props.account.trial_starts_at"
                                            :end-at="$page.props.account.trial_ends_at"/>

                                        <TokenProgressBar :label="__('tokens available')"
                                                          :value-percentage="$page.props.account.token_usage.available_credit_percent"
                                                          :value="$page.props.account.token_usage.available_credit"/>

                                        <div class="-mx-2 top-0 flex py-2 items-center justify-between px-2 md:py-4">
                                            <div class="flex items-center">
                                                <Link :href="route('portal.profile.show')">
                                                    <span class="flex items-center cursor-pointer rounded-xl px-1 hover:bg-gray-50 hover:dark:bg-slate-700">
                                                        <span class="flex space-x-2 text-sm font-semibold leading-6 text-gray-900"
                                                            aria-hidden="true">
                                                            <Avatar :user="$page.props.auth" size="w-8 h-8"/>
                                                            <span class="relative mt-px">{{ $page.props.auth.nice_name }}</span>
                                                        </span>
                                                        <span
                                                            class="ml-2">
                                                            <UserInfo :user="$page.props.auth"/>
                                                        </span>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div class="-m-1.5 flex items-center p-1.5">
                                                <div class="flex space-x-2 font-semibold leading-6 p-2 rounded-md text-gray-400 hover:text-purple-600">
                                                    <Link :href="route('logout')" method="post" as="button">
                                                        <ArrowRightStartOnRectangleIcon class="w-6 h-6 item-center cursor-pointer"/>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
